import React from 'react';
import { graphql, Link } from 'gatsby';

import Layout from '@components/Layout/Layout';
import SEO from '@components/SEO/SEO';
import ResourceQueryProvider from '@providers/ResourceQuery/ResourceQuery';
import ResourceList from '@components/ResourceList/ResourceList';

const CategoryPage = ({ pageContext }) => {
  const { resources, category } = pageContext;
  return (
    <Layout>
      <>
        <SEO category={category} />
        <ResourceQueryProvider
          initialQuery={{ category: category.slug }}
          resources={resources}
          initialSize={30}
          pageSize={30}
        >
          <ResourceList className="mt-3" xs="1" md="2" lg="3" />
        </ResourceQueryProvider>
      </>
    </Layout>
  );
};

export default CategoryPage;
